<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"
      />
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"
      />
      <v-container class="container--fluid grid-list-md text-center">
        <h1 data-cy="wallet-type-title">Wallet Types</h1>
      </v-container>
      <v-card elevation="2" outlined shaped tile class="pa-1">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title>
            <v-container grid-list-md text-xs-center>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    data-cy="search-wallet-type"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-btn
                    :block="$vuetify.breakpoint.mdAndDown"
                    class="mt-2"
                    color="success"
                    data-cy="create-wallet-type"
                    @click.stop="showForm('CREATED')"
                    v-if="hasPermission('ROLE_CREATE_WALLET_TYPE')"
                    >Create Wallet Type</v-btn
                  >
                  <savings-product-type-form
                    ref="form"
                    :selected-item="selectedItem"
                    :is-show="showFormDialog"
                    :form-type="formType"
                    v-on:close-dialog="closeDialog"
                    v-on:save="save"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
        </div>
      </v-card>

      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="itemList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems.length"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr :colspan="headers.length">
              <th v-for="header in headers" :key="header.text">
                <span class="d-flex justify-center">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
              <td>
                <span class="d-flex justify-center"> {{ item.id }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.name }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.displayName }} </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    class="mr-2"
                    @click.stop="viewDetails(item)"
                  >
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    @click.stop="showForm('UPDATE', item)"
                    v-if="hasPermission('ROLE_UPDATE_WALLET_TYPE')"
                  >
                    <v-icon dark medium>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Found no results.
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import SavingsProductTypeForm from "@/views/components/SavingsProductTypeForm.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";

import { mapGetters } from "vuex";

export default {
  name: "SavingsProductTypes",
  components: {
    Loading,
    SavingsProductTypeForm,
    DetailsForm,
  },
  data() {
    return {
      search: "",
      headers: [],
      itemList: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      formType: "CREATE",
      selectedItem: {},
      totalItems: 0,
      options: {
        rowsPerPage: 5,
      },
      details: {
        title: "",
        item: {},
      },
      isShowDetails: false,
      showFormDialog: false,
      isHtml: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.queryData();
      },
    },
    deep: true,
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
  },
  async mounted() {
    this.setHeaders();
    this.queryData();
  },
  methods: {
    hasPermission(authority) {
      if (this.authorities.some((x) => x === authority)) {
        return true;
      }
      return false;
    },
    async queryData() {
      this.loading = true;
      try {
        const response = await API.getSavingProductType();
        console.log(response);
        if (!response || response.error) {
          //error getting data
          console.log(`${response.error}`);
        } else {
          this.totalItems = response.walletTypes.length;
          this.itemList = response.walletTypes.reverse();
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Display Name",
          value: "Display Name",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headers = headers;
    },
    showForm(what, item = {}) {
      this.formType = what;
      this.selectedItem = item;
      this.showFormDialog = true;
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = "";
        this.details.item = {};
        this.isShowDetails = false;
      } else if (type === "CREATED" || type === "UPDATE") {
        this.showFormDialog = false;
      } else if (type === "loading") {
        this.loadingDialog = false;
        this.$router.go(0);
      } else if (type === "loading-error") {
        this.loadingDialog = false;
      }
    },
    async save(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      if (value) {
        if (value.formType === "CREATED") {
          this.loadingMessage = `Creating new wallet type <strong><i>${value.name}</i></strong>`;
          setTimeout(async () => {
            const createResponse = await API.createSavingProductType(value);
            this.loadingFinished = true;
            if (!createResponse || createResponse.error) {
              this.isHtml = false;
              this.loadingMessage = createResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created wallet type <strong><i>${value.name}</i></strong>`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "UPDATE") {
          this.loadingMessage = `Updating wallet type <strong><i>${value.name}</i></strong>`;
          setTimeout(async () => {
            const updateResponse = null;
            //const updateResponse = await API.updateSavingsProducts(value);
            this.loadingFinished = true;
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateResponse.error.message;
            } else {
              this.loadingMessage = `Successfully updated wallet type <strong><i>${value.name}</i></strong>`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else {
          this.loadingMessage = "Invalid command";
          this.loadingFinished = true;
        }
      } else {
        this.loadingMessage = "Error processing data.";
        this.loadingFinished = true;
      }
    },
    viewDetails(details) {
      this.details.title = details.externalId;
      this.details.item = Object.assign({}, details);
      this.isShowDetails = true;
    },
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
