<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'UPDATE'">Update Wallet Type</span>
          <span class="headline" v-else>Create New Wallet Type</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Name*"
                    :readonly="formType === 'UPDATE'"
                    v-model="form.name"
                    data-cy="name"
                    required
                    hint="wallet type identifier"
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Display Name*"
                    v-model="form.displayName"
                    data-cy="display-name"
                    required
                    hint="name to be displayed for the wallet type"
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Description"
                    v-model="form.description"
                    data-cy="description"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field label="Remarks" v-model="form.remarks"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-model="form.isEnabled"
                    data-cy="is-enabled"
                    flat
                    :label="`Enabled: ${form.isEnabled}`"
                    :readonly="formType !== 'UPDATE'"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-cy="close" color="blue darken-1" text v-on:click="close">Close</v-btn>
          <v-btn data-cy="save" color="blue darken-1" text v-on:click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
  },
  computed: {
    populatedForm() {
      return {
        name: this.form.name,
        displayName: this.form.displayName,
        description: this.form.description,
        remarks: this.form.remarks,
        isEnabled: this.form.isEnabled,
        formType: this.formType,
      };
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    deep: true,
  },
  data() {
    return {
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,
      form: {},
      rules: {
        requiredField: (v) => !!v || "Required field.",
      },
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        this.$emit("save", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      if (this.formType === "CREATED") {
        this.form = {
          name: undefined,
          displayName: undefined,
          description: undefined,
          remarks: undefined,
          isEnabled: true,
        };
      } else if (this.formType === "UPDATE" && this.selectedItem) {
        this.form = {
          name: this.selectedItem.name,
          displayName: this.selectedItem.displayName,
          description: this.selectedItem.description,
          remarks: this.selectedItem.remarks,
          isEnabled: this.selectedItem.isEnabled,
        };
      }
    },
  },
};
</script>
